import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import { Container } from '../../components/Scaffold'
import Iframe from '../../components/Iframe'

const ExamplesContainer = styled.div`
  margin-top: 80px;
`

export default () => {
  return (
    <Layout title='Radio & Podcasting - Faith Howley'>
      <Container maxWidth={640}>
        <h1>Radio & Podcasting</h1>
        <p>As a radio producer I have experience running live radio shows, editing and publishing podcasts. Below is a selection of examples from my past work.</p>
        <ExamplesContainer>
          <Iframe title="Showreel" width="100%" height="166" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/929241619&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
          <Iframe title="Hearing Distance" width="100%" height="166" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/442980513&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
          <Iframe title="Journey To A Witch's House" width="100%" height="166" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/392948580&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
          <Iframe title="Me encanta la salsa" width="100%" height="166" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/324088260&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
          <Iframe title="'Do you want a cup of tea love?'" width="100%" height="166" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/312527957&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
          <Iframe title="Poem - with a twist" width="100%" height="166" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/312527259&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
          <Iframe title="The Doorstep Tales of 52 Maddison" width="100%" height="166" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/312523829&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
        </ExamplesContainer>
      </Container>
    </Layout>
  )
}
