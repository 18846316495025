import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LoadingIcon from './LoadingIcon'

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => p.width};
  height: ${p => p.height}px;
`

const Iframe = ({ title, width, height, src }) => {
  const [loading, setLoading] = useState(true)

  return (
    <div>
      {loading ? (
        <SpinnerContainer width={width} height={height}>
          <LoadingIcon />
        </SpinnerContainer>
      ) : null}
      <iframe title={title} width={width} height={height} scrolling="no" frameBorder="no" allow="autoplay" src={src} onLoad={() => setLoading(false)}></iframe>
    </div>
  )
}

Iframe.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
}

export default Iframe
