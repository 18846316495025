import React from 'react'
import styled, { keyframes } from 'styled-components'

const offset = 187
const duration = '1.4s'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(270deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dashoffset: ${offset};
  }

  50% {
    stroke-dashoffset: ${offset /4};
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: ${offset};
    transform: rotate(450deg);
  }
`

const Spinner = styled.svg`
  animation: ${rotate} ${duration} linear infinite;
`

const Circle = styled.circle`
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} ${duration} ease-in-out infinite;
  stroke: black;
`

export default () => (
  <Spinner
    width="24px"
    height="24px"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle
      fill="none"
      strokeWidth="6"
      cx="33"
      cy="33"
      r="30"
    ></Circle>
   </Spinner>
)
